<template>
  <v-card>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :options.sync="options"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :server-items-length="total"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import { formatCurrency } from "@/utils/formatting";

export default {
  name: "CaTable",
  components: {},
  data() {
    return {
      numberOfPages: 0,
      items: [],
      loading: false,
      optionsInitialized: false,
      forceCall: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 100,
      },
      headers: [
        {
          text: "Site",
          value: "site",
        },
        {
          text: "CA",
          value: "ca",
        },
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    await this.$store.dispatch("common/getSiteList");
    await this.$store.dispatch("common/getSiteGroupList");
  },
  methods: {
    formatResult(items) {
      console.log("siteList", this.siteList);
      return items.map((item, index) => {
        if (index === 0) {
          console.log("item", item);
        }

        const site = this.siteList.find((site) => site.id === item.site_id);

        return {
          site: site?.name ?? "",
          ca: formatCurrency(item.ca),
        };
      });
    },
    async getData() {
      if (!this.applyFilters && !this.forceCall) {
        return;
      }

      if (this.monthDate.length !== 7) {
        return;
      }

      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await this.axiosGet("/sites/consolidated-ca/", {
        month_date: this.monthDate,
        sites: this.sites.join(","),
        page: page,
        limit: itemsPerPage,
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : {}),
      });
      this.items = this.formatResult(data.items);
      this.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;

      this.loading = false;
      this.forceCall = false;

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    sites() {
      return this.$store.getters["admin/getSites"];
    },
    monthDate() {
      return this.$store.getters["admin/getMonthDate"];
    },
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    siteList() {
      return this.$store.getters["common/getSiteList"];
    },
  },
  watch: {
    options() {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.forceCall = true;
      }

      this.optionsInitialized = true;
      this.getData();
    },
    applyFilters() {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped></style>
